/*
 * Modified by Leonel Menjivar on 22-04-2023:
 * - Added show_supplier, isRetail, and isRestaurant subjects to enable sharing data related to supplier, retail, and restaurant information
 */

import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  public currentMoneySubject:   Subject<string>   = new Subject<string>();
  public currentTaxSubject:     Subject<number>   = new Subject<number>();
  public alert_new_order_sound: Subject<boolean>  = new Subject<boolean>();
  public is_super:              Subject<boolean>  = new Subject<boolean>();
  public uid:                   Subject<string>   = new Subject<string>();
  public appName:               Subject<string>   = new Subject<string>();
  public primary_color:         Subject<string>   = new Subject<string>();
  public secondary_color:       Subject<string>   = new Subject<string>();

  public show_supplier:         Subject<boolean>   = new Subject<boolean>();
  public isRetail:              Subject<boolean>   = new Subject<boolean>();
  public isProjectService:      Subject<boolean>   = new Subject<boolean>();
  public isRestaurant:          Subject<boolean>   = new Subject<boolean>();
  public isAdminMemberShip:     Subject<boolean>   = new Subject<boolean>();
  public isOnlyView:     Subject<boolean>   = new Subject<boolean>();
  public unique_tax:            Subject<boolean>   = new Subject<boolean>();
  public transactions:          Subject<any>       = new Subject<any>();

  constructor() {
  }

}
