import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import MetisMenu from 'metismenujs';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { NavigationEnd, Router } from '@angular/router';
import { HttpService } from "../../../services/http.service";
import { RolesService } from "../../../services/roles.service";
import { FirestoreService } from "../../../services/firestore.service";
import { Subscription } from "rxjs";
import { LocalStorageService } from "../../../services/local-storage.service";
import { CommonService } from "../../../services/common.service";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;

  menuItems: MenuItem[] = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;
  private settings: any;
  public appName: string = '';
  public theme: string = '';
  public isRetail: boolean = false;
  private isRestaurant: boolean = false;
  public isAdminMemberShip: Boolean = true;
  public isProjectService: Boolean = localStorage.getItem('isProjectService') == 'true';
  public isOnlyView: Boolean = localStorage.getItem('isOnlyView') == 'true';

  public menu: any[] = [];
  public allMenu: any[] = [];
  public preparingMenu: any[] = [];
  public logo: string = '';
  valorSubscription: Subscription;
  subMenu: Subscription;
  private valor: string;

  collectionName = 'AdminMenu';
  collectionField = 'option_menu_lastupdateday';
  orderByDir: any = 'asc';
  adminMenu: Subscription;
  infoUser: Subscription;
  private user_uid: string = localStorage.getItem('user_uid') || '';
  is_super: Boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private service: HttpService,
    private roles: RolesService,
    private firestore: FirestoreService,
    private variablesLocales: LocalStorageService,
    private commonService: CommonService,
    router: Router,
    private auth: AuthService,
  ) {
    this.menu = JSON.parse(localStorage.getItem('menu')) || [];
    this.auth.getUser2().then(async userLocal => {
      this.user_uid = userLocal.uid;
      this.is_super = userLocal.is_super;
      this.getMenu();
    })
    this.gettypeBusiness();
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }
      }
    });
    this.theme = localStorage.getItem('theme');
    if (this.theme != '') this.document.body.classList.add(this.theme);
    this.service.sendGET_OneSettingsApp("General").subscribe((data) => {
      this.settings = data;
      this.appName = this.settings.appName;
      this.isRestaurant = this.settings.isRestaurant;
      this.isRetail = this.settings.isRetail;
      this.isAdminMemberShip = this.settings.isAdminMemberShip;
    });
    // this.service.sendGET_Menu().subscribe( res => {
    // res.forEach( element => {
    // // if (element.label != undefined) this.menu.push( element );
    // if (element.option_menu_active){
    // element.label = element.option_menu_name;
    // element.icon = element.option_icon;
    // element.link = element.option_menu_url.toLowerCase();
    // element.hidden = false;
    // if (element.onlyRestaurant) element.hidden = !this.isRestaurant;
    // if (element.onlyRetail) element.hidden = !this.isRetail;
    // if (element.link != '/dashboard') this.roles.verifyRoles(element.link).then( value => element.hasPermissions = value );
    // else element.hasPermissions = true;
    // this.menu.push( element );
    // }
    // })
    // this.menu = this.menu.sort((a, b) => {
    // if (a.option_menu_order < b.option_menu_order) {
    // return -1;
    // } else if (a.option_menu_order > b.option_menu_order) {
    // return 1;
    // }
    // return 0;
    // })
    // // console.log('this.menu', this.menu)
    // })
    this.service.sendGET_SettingsApp().subscribe((data) => {
      this.logo = data.register_screen_commerce_logo;
    });
  }

  ngOnInit(): void {
    this.menuItems = MENU;
    this.menuItems = this.menu;
    this.valorSubscription = this.variablesLocales.getValorObservable('typeBusiness').subscribe(
      (valor: string) => {
        this.valor = valor;
        let typeBusiness = JSON.parse(valor);
        this.isRetail = typeBusiness.isRetail;
        this.isRestaurant = typeBusiness.isRestaurant;
        this.isAdminMemberShip = typeBusiness.isAdminMemberShip;
      }
    );
    this.subMenu = this.variablesLocales.getValorObservable('menu').subscribe(
      (valor: string) => {
        this.menu = JSON.parse(valor);
        // console.log('menu update', this.menu);
      }
    );
    this.infoUser = this.variablesLocales.valorAdminObservable().subscribe(
      (valor: string) => {
        // localStorage.setItem('is_super', valor);
        // this.getMenu();
      }
    );

    /**
     * Escucha adminMenu
     */
    this.adminMenu = this.firestore.search(this.collectionName, {
      'orderByName': this.collectionField,
      'orderByDir': this.orderByDir,
    }).subscribe(res1 => {
      // console.log('escucha adminMenu')
      this.updateSidebar();
    })


    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addEventListener('change', () => {
      this.iconSidebar;
    });
    this.iconSidebar(desktopMedium);
  }

  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  gettypeBusiness() {
    let typeBusinessJSON = localStorage.getItem('typeBusiness');
    if (typeBusinessJSON) {
      let typeBusiness = JSON.parse(typeBusinessJSON);
      this.isRetail = typeBusiness?.isRetail;
      this.isRestaurant = typeBusiness?.isRestaurant;
      this.isAdminMemberShip = typeBusiness?.isAdminMemberShip;

      this.updateMenu();
    }

  }

  updateMenu() {
    this.menu.forEach(value => {
      if (value.onlyRestaurant) value.hidden = !this.isRestaurant;
      if (value.onlyRetail) value.hidden = !this.isRetail;
      if (value.onlyAdminMemberShip) value.hidden = !this.isAdminMemberShip;
      if (value.onlyProjectService) value.hidden = !this.isProjectService;
      if (value.onlyOnlyView) value.hidden = !this.isOnlyView;
    })
  }

  getMenu() {
    // console.log('get menu');
    // let is_super = localStorage.getItem('is_super');
    let is_super = this.is_super;
    // console.log('user_uid', this.user_uid);
    this.allMenu = [];
    this.menu = [];
    this.preparingMenu = [];
    // localStorage.removeItem("menu");
    this.firestore.getWhere('AdminMenu', 'option_menu_active', true).subscribe(res => {
      // console.log('allMenu', res);
      this.preparingMenu.length = 0;
      let allMenu = res.sort((a, b) => {
        if (a.option_menu_order < b.option_menu_order) {
          return -1;
        } else if (a.option_menu_order > b.option_menu_order) {
          return 1;
        }
        return 0;
      });
      allMenu.forEach(element => {
        this.addItemMenu(element, false);
        if (element.is_public || is_super == true) {
          this.addItemMenu(element);
        } else {
          this.firestore.getSubcollectionWhere('AdminMenu', element.uid, 'Users', 'uid', this.user_uid)
            .subscribe(response => {
              // console.log("AdminMenu:", element.uid, response);
              if (response.length > 0 && response[0]['view'] == true) {
                this.addItemMenu(element);
              }
            })
        }
      })
    })

    this.menu = this.preparingMenu;
    this.menu = this.menu.sort((a, b) => {
      if (a.option_menu_order < b.option_menu_order) {
        return -1;
      } else if (a.option_menu_order > b.option_menu_order) {
        return 1;
      }
      return 0;
    });
    setTimeout(() => {
      this.updateSidebar();
    }, 1e3)

  }

  addItemMenu(element, type = true) {
    element.label = element.option_menu_name;
    element.icon = element.option_icon;
    element.link = element.option_menu_url.toLowerCase();
    element.hidden = false;
    element.hasPermissions = true;
    if (element.onlyRestaurant) element.hidden = !this.isRestaurant;
    if (element.onlyRetail) element.hidden = !this.isRetail;
    if (element.onlyProjectService) element.hidden = !this.isProjectService;
    if (element.onlyOnlyView) element.hidden = !this.isOnlyView;

    type ? this.preparingMenu.push(element) : this.allMenu.push(element);
  }

  updateSidebar() {
    localStorage.removeItem("menu");
    // console.log('preparing menu', this.preparingMenu);
    let menuJSON = JSON.stringify(this.preparingMenu);
    localStorage.setItem('menu', menuJSON);
    this.allMenu = this.allMenu.sort((a, b) => {
      if (a.option_menu_order < b.option_menu_order) {
        return -1;
      } else if (a.option_menu_order > b.option_menu_order) {
        return 1;
      }
      return 0;
    });
    let allMenuJSON = JSON.stringify(this.allMenu);
    localStorage.setItem('allMenu', allMenuJSON);
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e: Event) {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }


  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }


  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.add("open-sidebar-folded");
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(mq: MediaQueryList) {
    if (mq.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }


  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event: Event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    localStorage.setItem('theme', (<HTMLInputElement>event.target).value);
    this.document.body.classList.add((<HTMLInputElement>event.target).value);
    this.document.body.classList.remove('settings-open');
  }


  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }


  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }


  /**
   * Resets the menus
   */
  resetMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove('mm-active');
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove('mm-show');
        }

        const parent3El = parent2El?.parentElement;
        if (parent3El) {
          parent3El.classList.remove('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.remove('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove('mm-active');
            }
          }
        }
      }
    }
  };


  /**
   * Toggles the menu items
   */
  activateMenuItems() {

    const links: any = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]['pathname']) {

        menuItemEl = links[i];

        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.add('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add('mm-active');
            }
          }
        }
      }
    }
  };


}
